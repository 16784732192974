<template>
  <div>
    <indexMenu></indexMenu>
    <router-view></router-view>
    <indexFooter></indexFooter>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import indexFooter from "./components/indexFooter.vue";
import indexMenu from "./components/indexMenu.vue";
export default {
  data() {
    return {};
  },
  components: {
    indexMenu,
    indexFooter
  }
};
// 统计代码
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?246ea0c5402b9a4f6b99df745ab876c2";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
</script>

<style>
@import url("https://res.hlxuan.top/website/hlxuanUI.css");
.el-backtop {
  color: #82c8a0 !important;
}
.el-card {
  border-radius: 10px !important;
}
.el-carousel {
  border-radius: 10px !important;
}
.hlxuanUI-content {
  display: block;
}
</style>