<template>
  <div class="hlxuanUI-content" align="center">
    <div v-for="(item, index) in List" :key="index">
      <h2 align="justify">{{ item.title }}</h2>
      <el-row :gutter="24">
        <el-col :span="8" v-for="(itm, idx) in item.content" :key="idx" style="margin:10px 0px;">
          <el-card class="card" :body-style="{ padding: '0px' }" shadow="hover">
            <img :src="itm.img" class="image" />
            <div style="padding: 14px;">
              <div class="title">{{ itm.title }}</div>
              <div class="desc">{{ itm.introduction }}</div>
              <br />
              <a :href="itm.url" target="_blank"
                ><el-button
                  style="background-color: #82c8a0; color: white"
                  round
                  size="mini"
                  >点击查看</el-button
                ></a
              >
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      List: [
        {
          title: "微信小程序",
          content: [
            {
              title: "朗月传媒工作室",
              introduction: "这是一款提供生活服务的商城小程序。",
              url: "//doc.hlxuan.top/product/LangyueMedia/",
              img: "https://res.hlxuan.top/website/work/langyuemedia-miniprogram.png",
            },
            {
              title: "嘟嘟嘟KD",
              introduction:
                "这是一款校园服务小程序，主要提供校园快递代拿、跑腿服务。\n没有找到你所在的高校？联系客服补充或申请成为校区负责人吧！",
              url: "//doc.hlxuan.top/product/DuDuDu-KD/",
              img: "https://res.hlxuan.top/website/work/dududuKD-miniprogram.png",
            },
          ],
        },
        {
          title: "影视作品",
          content: [
            {
              title: "《冬日里的花香》",
              introduction: "",
              url: "https://mp.weixin.qq.com/s?__biz=Mzg4NDM4MzUzNw==&mid=2247484834&idx=1&sn=7516b63652db3e9b04f41472366e0da2&chksm=cfb84ffcf8cfc6eaaf6bc0d14bdc545b31486d971e86b2354c8a7bcc1ed517533a5a2eab007f&token=1084967984&lang=zh_CN#rd",
              img: "https://images.hlxuan.top/2023/05/672468bf955fe68f.png",
            },
            {
              title: "《粉红盛宴“广州樱”》",
              introduction: "",
              url: "https://mp.weixin.qq.com/s?__biz=MzI1NTAzMjYwOQ==&mid=2650309329&idx=1&sn=f12264e50121a6ace5c1b752ce61281b&scene=19#wechat_redirect",
              img: "https://images.hlxuan.top/2023/05/d58c3517cda555f5.jpg",
            },
            {
              title: "《樱花盛开时》",
              introduction: "",
              url: "https://mp.weixin.qq.com/s?__biz=MzI1NTAzMjYwOQ==&mid=2650309217&idx=1&sn=4cb25d46b6ed282df8d3d201fe5de322&scene=19#wechat_redirect",
              img: "https://images.hlxuan.top/2023/05/3d1f427756499ca6.jpg",
            },
            {
              title: "《一起来看岭南“雪景”》",
              introduction: "",
              url: "https://mp.weixin.qq.com/s?__biz=MzI1NTAzMjYwOQ==&mid=2650309170&idx=1&sn=ed5620e2a693727dced79e04eb05dedb&scene=19#wechat_redirect",
              img: "https://images.hlxuan.top/2023/05/7a87d8cc5bcb9b78.jpg",
            }
          ],
        },
      ],
    };
  },
};
</script>

<style>
.title {
  color: #4c4c4c;
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
  word-break: break-all;
}
.desc {
  color: #888888;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  text-align: justify;
  white-space: pre-wrap;
}
.image {
  width: 100%;
  display: block;
}
</style>