<template>
  <div class="hlxuanUI-foot">
    <div class="hlxuanUI-content" style="text-align: center;">
      <el-image style="height: 56px; width: 56px;" src="./images/logo.png" fit="contain" />
      <p>
        <ul class="hlxuanUI-foot_ul">
          <li><a href="javascript:void(0);"><router-link to="/">首页</router-link></a></li>
          <li><a href="javascript:void(0);"><router-link to="/news">新闻中心</router-link></a></li>
          <li><a href="javascript:void(0);"><router-link to="/projectCases">项目案例</router-link></a></li>
          <li><a href="javascript:void(0);"><router-link to="/newMedia">新媒体平台</router-link></a></li>
          <li><a href="javascript:void(0);"><router-link to="/contact">联系我们</router-link></a></li>
          <li><a href="javascript:void(0);"><router-link to="/about">关于我们</router-link></a></li>
        </ul>
      </p>
      <p>技术支持：<a class="hlxuanUI-foot_link" href="//www.hlxuan.top" target="_blank">Hlxuan.</a></p>
      <p>
        邮箱：<a
          href="mailto:langyuemedia@hlxuan.top"
          class="hlxuanUI-foot_link"
          >langyuemedia@hlxuan.top</a
        >
      </p>
      <p>
        Copyright ©{{ CopyrightYear }}
        <a class="hlxuanUI-foot_link" href="//langyuemedia.hlxuan.top">广州市黄埔区朗月传媒工作室</a> All
        right reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      CopyrightYear: "2021",
    };
  },
  methods: {
    CopyrightYear_() {
      var startYear = 2021;
      if (new Date().getFullYear() > startYear) {
        this.CopyrightYear = startYear + "-" + new Date().getFullYear();
      }
    },
  },
  mounted() {
    this.CopyrightYear_();
  },
};
</script>
