import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import VueRouter from 'vue-router'

import router from './router/index'
Vue.config.productionTip = false

import axios from "axios";
Vue.prototype.axios = axios;

Vue.use(ElementUI)
Vue.use(VueRouter)
router.beforeEach((to, from, next) => {
  if (Object.keys(to.meta).length > 0 && to.matched.length > 0) {
    let this_meta = to.matched[to.matched.length - 1].meta
    if (this_meta.title) document.title = this_meta.title
    let head = document.getElementsByTagName('head');
    let meta_keyword = document.createElement('meta');
    if (document.querySelector('meta[name="keywords"]')) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', this_meta.keywords)
    } else {
      meta_keyword.setAttribute('name', 'keywords')
      meta_keyword.setAttribute('content', this_meta.keywords)
      head[0].appendChild(meta_keyword)
    }
    let meta_description = document.createElement('meta');
    if (document.querySelector('meta[name="description"]')) {
      document.querySelector('meta[name="description"]').setAttribute('content', this_meta.description)
    } else {
      meta_description.setAttribute('name', 'description')
      meta_description.setAttribute('content', this_meta.description)
      head[0].appendChild(meta_description)
    }
  }
  // 判断该页面是否需要登录
  let token = sessionStorage.getItem("username");
  if (to.path.indexOf("/admin") != -1 && to.meta.auth) {
    // 如果token存在直接跳转
    if (token) {
      next();
    } else {
      // 否则跳转到login登录页面
      next({
        path: '/admin/login',
        query: {
          isAuth: false,
        }
      });
    }
  } else {
    // 如果不需要登录，则直接跳转到对应页面
    next();
  }
})

new Vue({
  el: '#app',
  render: h => h(App),
  router: router,
});
