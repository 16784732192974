<template>
  <div class="hlxuanUI-content">
    <el-table :data="tableData" stripe style="width: 100%;text-align:center;">
      <el-table-column prop="name" label="项目"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
    </el-table>
    <div class="desc" style="margin-top:20px;">
      <p>*注：</p>
      <ol>
        <li>需自行准备资质，我们只提供协助与指导服务。</li>
        <li>每个项目的价格都是单次服务的价格，不包后续更新和维护。</li>
        <li>多个服务一起购买，可联系客服获取报价。</li>
        <li>如有疑问，请咨询客服。</li>
      </ol>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      tableData: [
        // {
        //   name: "代注册并认证小程序",
        //   price: "¥50"
        // },
        {
          name: "协助购买及备案域名",
          price: "¥50"
        },
        {
          name: "协助购买及安装服务器",
          price: "¥50"
        },
        {
          name: "协助SSL证书申请及安装",
          price: "¥50"
        },
        {
          name: "协助配置CDN加速",
          price: "¥50"
        },
        {
          name: "其他服务",
          price: "请联系客服咨询"
        }
      ]
    };
  }
};
</script>