<template>
  <div class="hlxuanUI-content" style="width: 350px">
    <el-page-header @back="goBack" content="朗月传媒工作室"></el-page-header>

    <div style="height: 30px"></div>

    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="form.password"
          show-password
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="onSubmit('form')">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log("submit!");
          // 加载动画
          const loading = this.$loading({
            lock: true,
            text: "登陆中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let param = new URLSearchParams();
          param.append("username", this.form.username);
          param.append("password", this.form.password);
          // 请求数据库
          this.axios.post("login.php", param).then((res) => {
            console.log("login", res);
            if (res.data.code === 0) {
              console.log("登录成功");
              sessionStorage.setItem("username", this.form.username);
              this.$router.push("/admin/index");
              loading.close();
            } else {
              this.$message({
                message: "用户名或密码错误",
                type: "warning",
              });
              loading.close();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack() {
      this.$router.push("/");
    },
  },
  mounted() {
    if (this.$route.query.isAuth === "false") {
      this.$notify({
        title: "提示",
        message: "请您先登录后再操作",
      });
    }
  },
};
</script>

<style>
.el-icon-loading {
  color: #82c8a0 !important;
}
.el-loading-spinner .el-loading-text {
  color: #82c8a0 !important;
}
</style>