<template>
    <div class="hlxuanUI-content">
        <el-card class="box-card" shadow="never">
            <template v-if="item">
                <div class="title">{{ item.title }}</div>
                <div class="content" v-html="item.content"></div>
                <div class="footer">
                    <div>浏览 {{ item.pageviews }}</div>
                    <div>发布于 {{ item.createTime }}</div>
                    <div v-if="item.updateTime">最后一次编辑于 {{ item.updateTime }}</div>
                </div>
            </template>
            <el-empty :description="newsDescription" v-else v-loading="dataLoading"></el-empty>
        </el-card>
    </div>
</template>
<style scoped>
.box-card {
    width: 100%;
    margin: 30px auto;
}

.title {
    text-align: center;
    color: #000;
}

.footer {
    margin-top: 10px;
    color: var(--hlxuanUI-desc-text-color);
    font-size: 13px;
}
</style>
<script>
export default {
    data() {
        return {
            item: null,
            description: "",
            dataLoading: true,
        }
    },
    methods: {
        loadData(category, id) {
            this.axios
                .get(`/get_notice_detail.php?category=${category}&id=${id}`)
                .then((res) => {
                    if (res.data.errcode == 0) {
                        this.item = res.data.data
                    } else {
                        this.description = "数据加载失败，请稍后重试！";
                        this.dataLoading = false;
                    }
                })
                .catch(() => {
                    this.description = "数据加载失败，请稍后重试！";
                    this.dataLoading = false;
                });
        },
    },
    mounted() {
        const query = this.$route.query
        this.loadData(query.category, query.id)
    }
}
</script>