<template>
  <div class="hlxuanUI-content">
    <el-image src="/images/langyuemediastudio.png" fit="contain"></el-image>
    <p class="p">
      广州市黄埔区朗月传媒工作室成立于2021年，这是一家主要以小程序开发为主的传媒工作室，同时还提供摄影、摄像及视频制作、软件开发、网站制作等服务。
    </p>

    <br />

    <div align="center">
      <el-image
        src="/images/gallery.png"
        fit="contain"
        style="width: 10em"
      />
    </div>

    <li class="li">视频拍摄、剪辑案例</li>

    <iframe
      frameborder="0"
      src="https://v.qq.com/txp/iframe/player.html?vid=z3316jbcmo6"
      allowFullScreen="true"
    ></iframe>

    <iframe
      frameborder="0"
      src="https://v.qq.com/txp/iframe/player.html?vid=p3218h33upm"
      allowFullScreen="true"
    ></iframe>

    <iframe
      frameborder="0"
      src="https://v.qq.com/txp/iframe/player.html?vid=z3224tfqoz1"
      allowFullScreen="true"
    ></iframe>

    <iframe
      frameborder="0"
      src="https://v.qq.com/txp/iframe/player.html?vid=n3231d7kohi"
      allowFullScreen="true"
    ></iframe>

    <li class="li">小程序开发案例</li>

    <div class="hlxuanUI-codeimg">
      <div align="center">
        <img src="images/gh_e61bb643c51d_1280.jpg" alt="i朗月" width="280em" />
      </div>

      <div style="height: 10px"></div>

      <p align="center" style="font-size: 0.8em; color: #888888">
        微信扫一扫即可访问
      </p>
    </div>

    <div class="hlxuanUI-codeimg">
      <div align="center">
        <a href="//weixin.hlxuan.top/miniprogram/langyuemedia"
          ><img
            src="images/gh_7dd4d7203c81_1280.jpg"
            alt="朗月传媒工作室"
            width="280em"
        /></a>
      </div>

      <div style="height: 10px"></div>

      <p align="center" style="font-size: 0.8em; color: #888888">
        微信扫一扫即可访问
      </p>
    </div>

    <div class="hlxuanUI-codeimg">
      <div align="center">
        <a href="//weixin.hlxuan.top/miniprogram/DuDu"
          ><img
            src="images/gh_894dbcd58203_1280.jpg"
            alt="嘟嘟嘟KD"
            width="280em"
        /></a>
      </div>

      <div style="height: 10px"></div>

      <p align="center" style="font-size: 0.8em; color: #888888">
        微信扫一扫即可访问
      </p>
    </div>

    <div class="hlxuanUI-divline" style="margin: 30px auto"></div>

    <div align="center">
      <img src="https://res.hlxuan.top/website/officialAccount-langyuemedia.png" alt="" width="100%" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.p {
  text-indent: 2em;
  letter-spacing: 1px;
  line-height: 1.5;
  text-align: justify;
}

.li {
  text-indent: 2em;
  list-style: circle;
  color: #82c8a0;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
}

iframe {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1029px) {
  iframe {
    height: 450px;
  }
}
</style>