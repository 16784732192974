// 导入VueRouter插件
import VueRouter from 'vue-router'

// 导入需要显示的组件
import homePage from "../components/homePage.vue"
import news from "../components/news.vue"
import projectCases from "../components/projectCases.vue"
import ourService from "../components/ourService.vue"
import newMedia from "../components/newMedia.vue"
import contactUs from "../components/contactUs.vue"
import aboutUs from "../components/aboutUs.vue"
import noticeDetail from "../components/noticeDetail.vue"

import adminIndex from "../components/admin/index"
import adminLogin from "../components/admin/login"

// 创建一个路由的实例对象, 并传递 配置对象
const vr = new VueRouter({
    // routes是路由配置项, 其类型为数组
    routes: [
        // 每一个元素都是一个路由,其值为对象类型
        {
            path: "/",
            component: homePage,
            meta: {
                title: "朗月传媒工作室",
                content: {
                    keywords: "朗月传媒,朗月传媒工作室,嘟嘟嘟KD",
                    description: "广州市黄埔区朗月传媒工作室成立于20221年，这是一家主要以摄影、摄像及视频制作为主的传媒工作室，同时还提供软件开发服务。",
                }
            }
        },
        {
            path: "/news",
            component: news,
            meta: {
                title: "新闻中心 - 朗月传媒工作室",
            }
        },
        {
            path: "/service",
            component: ourService,
            meta: {
                title: "服务 - 朗月传媒工作室",
            }
        },
        {
            path: "/projectCases",
            component: projectCases,
            meta: {
                title: "项目案例 - 朗月传媒工作室",
            }
        },
        {
            path: "/newMedia",
            component: newMedia,
            meta: {
                title: "新媒体平台 - 朗月传媒工作室",
            }
        },
        {
            path: "/contact",
            component: contactUs,
            meta: {
                title: "联系我们 - 朗月传媒工作室",
            }
        },
        {
            path: "/about",
            component: aboutUs,
            meta: {
                title: "关于我们 - 朗月传媒工作室",
            }
        },
        {
            path:"/notice/detail",
            component: noticeDetail,
            meta:{
                title:"通知详情 - 朗月传媒工作室"
            }
        },
        {
            path: "/admin/login",
            component: adminLogin,
            meta: {
                title: "登录 - 后台管理系统 - 朗月传媒工作室",
            }
        },
        {
            path: "/admin",
            component: adminIndex,
            meta: {
                title: "首页 - 后台管理系统 - 朗月传媒工作室",
                auth: true,
            },
            children: [
                {
                    path: "index",
                    component: adminIndex,
                    meta: {
                        title: "首页 - 后台管理系统 - 朗月传媒工作室",
                        auth: true,
                    },
                }
            ]
        },
    ],
})

// 导出路由实例
export default vr;