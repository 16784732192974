<template>
  <div class="hlxuanUI-content">
    <!-- 轮播图 -->
    <div class="block text-center" style="width: 100%">
      <el-carousel
        indicator-position="outside"
        :height="bannerHeight + 'px'"
        v-if="banner.length > 0"
        v-loading="bannerDataLoading"
      >
        <el-carousel-item v-for="item in banner" :key="item.src">
          <a :href="item.url" target="_blank" style="height: 100%; width: 100%">
            <img
              :src="item.src"
              ref="bannerHeight"
              @load="imgLoad"
              class="bannerImg"
            />
          </a>
        </el-carousel-item>
      </el-carousel>
      <el-empty
        :description="bannerDescription"
        v-else
        v-loading="bannerDataLoading"
      ></el-empty>
    </div>
    <el-divider></el-divider>
    <div class="Service-Sector" style="text-align: center">
      <div class="titleArea">
        <span class="title_zh-cn">服务领域</span>
        <span class="title_en">Service Sector</span>
      </div>
      <el-row :gutter="20" style="font-size: 18px">
        <el-col :span="8">
          <i class="el-icon-video-camera"></i>
          <br />摄影摄像<br /><span class="title_en">Photography</span>
        </el-col>
        <el-col :span="8">
          <i class="el-icon-scissors"></i>
          <br />
          后期制作<br /><span class="title_en">Video Processing</span></el-col
        >
        <el-col :span="8"
          ><i class="el-icon-picture-outline-round"></i
          ><br />图像处理<br /><span class="title_en"
            >Image Processing</span
          ></el-col
        >
        <el-col :span="8"
          ><i class="el-icon-connection"></i><br />公众号开发<br /><span
            class="title_en"
            >Official Account Development</span
          ></el-col
        >
        <el-col :span="8"
          ><i class="el-icon-mobile-phone"></i><br />小程序开发<br /><span
            class="title_en"
            >Miniprogram Development</span
          ></el-col
        >
        <el-col :span="8"
          ><i class="el-icon-monitor"></i><br />网站制作<br /><span
            class="title_en"
            >Website Production</span
          ></el-col
        >
      </el-row>
    </div>
    <el-divider></el-divider>
    <div class="news">
      <div class="titleArea" style="text-align: center; margin-bottom: 20px">
        <span class="title_zh-cn">新闻动态</span>
        <span class="title_en">News</span>
      </div>
      <el-row
        :gutter="24"
        v-if="newsData.length > 0"
        v-loading="newsDataLoading"
      >
        <el-col style="margin: 10px 0px">
          <div v-for="(item, index) in newsData" :key="index" style="margin: 10px 0px">
            <el-card
              class="card"
              :body-style="{ padding: '0px' }"
              shadow="hover"
              v-if="index < 6"
            >
              <div style="padding: 14px">
                <el-link :underline="false" :href="item.url" target="_blank"
                  ><div class="title">{{ item.title }}</div></el-link
                >
                <div class="desc">{{ item.releaseDate }}</div>
              </div>
            </el-card>
          </div>
          <el-card class="card" :body-style="{ padding: '0px' }" shadow="hover">
            <div style="padding: 14px;text-align:center;">
              <el-link :underline="false" target="_blank"
                ><router-link to="/news" style="text-decoration: none;"
                  ><div class="title">查看更多</div></router-link
                ></el-link
              >
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-empty
        :description="newsDescription"
        v-else
        v-loading="newsDataLoading"
      ></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: [],
      bannerHeight: "",
      newsData: [],
      newsDataLoading: true,
      newsDescription: "新闻动态空空如也",
    };
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      });
    },
    loadData() {
      // 加载「轮播图」
      this.axios
        .get("./banner.php")
        .then((res) => {
          console.log("banner",res);
          if (typeof res.data == "object") {
            this.banner = res.data;
            this.bannerDataLoading = false;
            this.imgLoad();
          } else {
            this.bannerDescription = "数据加载失败，请稍后重试！";
            this.bannerDataLoading = false;
          }
        })
        .catch(() => {
          this.bannerDescription = "数据加载失败，请稍后重试！";
          this.bannerDataLoading = false;
        });

      // 加载「新闻动态」
      this.axios
        .get("./article.php")
        .then((res) => {
          console.log("article",res);
          if (res.data.errcode == 0) {
            this.newsData = res.data.item;
            this.newsDataLoading = false;
          } else {
            this.newsDescription = "数据加载失败，请稍后重试！";
            this.newsDataLoading = false;
          }
        })
        .catch(() => {
          this.newsDescription = "数据加载失败，请稍后重试！";
          this.newsDataLoading = false;
        });
    },
  },
  mounted() {
    this.loadData();
    window.addEventListener(
      "resize",
      () => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
        this.imgLoad();
      },
      false
    );
  },
};
</script>

<style>
.bannerImg {
  vertical-align: middle;
  width: 100%;
}
.Service-Sector i {
  font-size: 72px;
  text-align: center;
  margin-bottom: 10px;
}
.Service-Sector .el-col {
  padding: 20px !important;
}
.Service-Sector .titleArea {
  margin: 20px auto;
}
.title_zh-cn {
  font-size: 24px;
  font-weight: bold;
  display: block;
}
.title_en {
  font-size: 16px;
  color: #888888;
  display: block;
}
.title {
  color: #4c4c4c;
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
  word-break: break-all;
}
.title:hover {
  color: #82c8a0;
}
.desc {
  color: #888888;
  font-size: 14px;
}
</style>