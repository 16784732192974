<template>
  <div class="hlxuanUI-content">
    <el-row :gutter="24" v-if="newsData.length > 0" v-loading="newsDataLoading">
      <el-col style="margin: 10px 0px">
        <div
          v-for="(item, index) in newsData"
          :key="index"
          style="margin: 10px 0px"
        >
          <el-card class="card" :body-style="{ padding: '0px' }" shadow="hover">
            <div style="padding: 14px">
              <el-link :underline="false" :href="item.url" target="_blank"
                ><div class="title">{{ item.title }}</div></el-link
              >
              <div class="desc">{{ item.releaseDate }}</div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-empty
      :description="newsDescription"
      v-else
      v-loading="newsDataLoading"
    ></el-empty>
    <div class="hlxuanUI-center" style="text-align: center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="page_size"
        :total="total"
        :current-page.sync="current_page"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current_page: 1,
      total: 1,
      page_size: 10,
      newsData: [],
    };
  },
  methods: {
    loadData() {
      console.log("loading", this.current_page);
      // 加载「新闻动态」
      this.axios
        .get("/article.php?page=" + this.current_page)
        .then((res) => {
          if (res.data.errcode == 0) {
            this.newsData = res.data.item;
            this.all_page = res.data.all_page;
            this.total = res.data.total;
            this.newsDataLoading = false;
          } else {
            this.newsDescription = "数据加载失败，请稍后重试！";
            this.newsDataLoading = false;
          }
        })
        .catch(() => {
          this.newsDescription = "数据加载失败，请稍后重试！";
          this.newsDataLoading = false;
        });
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    current_page: {
      handler(newValue, oldValue) {
        console.log("current_page", newValue, oldValue);
        this.current_page = newValue;
        this.loadData();
      },
    },
  },
};
</script>
<style>
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #82c8a0 !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
    color: white !important;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #82c8a0 !important;
  }
</style>