<template>
  <div>
    <div class="hlxuanUI-head">
      <h2 class="hlxuanUI-head-title">
        <el-image
          src="./images/logo.png"
          style="width: 60px; height: 60px; vertical-align: middle"
        />
        <a href="/" style="margin-left: 10px">朗月传媒工作室</a>
      </h2>
    </div>
    <div :class="navBarFixed ? 'nav' : ''" style="transition: all 0.2s;">
      <el-menu
        :default-active="activeIndex"
        class="el-menu"
        mode="horizontal"
        background-color="#82c8a0"
        text-color="#ffffff"
        active-text-color="#ffffff"
        router
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/news">新闻</el-menu-item>
        <el-menu-item index="/service">服务</el-menu-item>
        <el-menu-item index="/projectCases">案例</el-menu-item>
        <el-menu-item index="/newMedia">新媒体</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-menu>
    </div>
    <div :style="{height: navBarFixed ? '80px' : ''}"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navBarFixed: false,
      activeIndex: "/",
    };
  },
  methods: {
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 310 时，实现吸顶效果
      if (scrollTop > 100) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  },
  mounted() {
    const path = this.$route.path;
    this.activeIndex = path;
    window.addEventListener("scroll",this.watchScroll)
  },
  destroyed(){
    window.removeEventListener("scroll",this.watchScroll)
  }
};
</script>

<style>
.el-menu {
  padding: 0 !important;
}
.nav {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9;
}
@media only screen and (min-width: 1029px) {
  .el-menu {
    padding: 0 10% !important;
  }
}
</style>