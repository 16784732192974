<template>
  <div class="hlxuanUI-content">
    <h2>新媒体平台</h2>

    <div class="hlxuanUI-MiniButton">
      <a
        @click="open('langyue_studio_gzh.jpg', '微信公众号（服务号）')"
        style="cursor: pointer"
        >微信公众号（服务号）</a
      >
      <a
        @click="open('langyue_studio_sph.png', '微信视频号')"
        style="cursor: pointer"
        >微信视频号</a
      >
      <a
        href="https://www.douyin.com/user/MS4wLjABAAAArGQANcTXK4awc2vi-jAnraFTkCb3IQf10lwax0s1ohn2vZtaY8l95yjr74Hb48DO?enter_from=recommend&enter_method=personal_panel"
        target="_blank"
        style="cursor: pointer"
        >抖音</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    open(src, title) {
      this.$alert(
        "<img src='/images/" + src + "' style='width:100%;' />",
        title,
        {
          confirmButtonText: "关闭",
          dangerouslyUseHTMLString: true,
        }
      );
    },
  },
};
</script>
