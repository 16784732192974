<template>
  <div class="hlxuanUI-content">
    <h2>联系我们</h2>
    <h4>您可以通过以下方式与我们取得联系</h4>

    <div class="hlxuanUI-MiniButton">
      <a
        href="https://www.hlxuan.top/customerService?type=langyuemedia"
        target="_blank"
        >在线客服</a
      >
      <!-- <a
        href="javascript:void(0);"
        @click="open('langyuemedia_wecom.png', '「朗月传媒」企业微信')"
        >企业微信</a
      > -->
      <a href="mailto:langyuemedia@hlxuan.top" target="_blank"
        >电子邮箱：langyuemedia@hlxuan.top</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      href: "javascript:void(0);",
    };
  },
  methods: {
    open(src, title) {
      this.$alert(
        "<img src='/images/" + src + "' style='width:100%;' />",
        title,
        {
          confirmButtonText: "关闭",
          dangerouslyUseHTMLString: true,
        }
      );
    },
  },
};
</script>
