<template>
  <div class="hlxuanUI-content">
    <h2 align="center">后台管理系统</h2>

    <el-tabs type="border-card">
      <el-tab-pane label="轮播图">
        <el-dialog title="上传图片" :visible.sync="dialogTableVisible">
          <el-upload
            class="upload-demo"
            drag
            action="upload_img.php"
            :limit="1"
            :data="updateData"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过10MB
            </div>
          </el-upload>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="新闻动态"> 新闻动态 </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogTableVisible: true,
      updateData: {
        directoryPath: "upload/",
        filename: new Date().getTime(),
      },
    };
  },
};
</script>